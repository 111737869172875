<template>
  <div class="component-custom-number-input">
    <b-row>
      <b-col
        v-if="slider"
        sm="12"
        :md="sliderMd"
        :xl="sliderXl"
        :order="sliderPosition === 'right' ? 2 : 1"
      >
        <b-form-input class="slider" type="range" :min="min" :max="max" :step="step"
          v-model="sliderVal"
          @input="onInputSlider"
        ></b-form-input>
      </b-col>
      <b-col
        sm="12"
        :md="slider ? (12 - sliderMd) : 12"
        :xl="slider ? (12 - sliderXl) : 12"
        :order="sliderPosition === 'right' ? 1 : 2"
        :class="sliderPosition === 'right' ? 'pr-0' : ''"
      >
        <b-form-input
          :class="calculatedInputClass"
          :min="min"
          :max="max"
          :step="step"
          v-model="val"
          @input="onInput"
          @focus="onFocus"
          @blur="onBlur"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'CustomNumberInput',
  props: {
    value: {
      type: [Number, String]
    },
    precision: {
      type: Number,
      default: 0
    },
    slider: {
      type: Boolean,
      default: false
    },
    step: {
      type: Number,
      default: 1
    },
    inputClass: {
      type: [String, Object],
      default: ''
    },
    inputId: {
      type: String,
      default: ''
    },
    sliderPosition: {
      type: String,
      default: 'left'
    },
    min: {
      type: Number,
      default: -999999999
    },
    max: {
      type: Number,
      default: 999999999
    },
    sliderXl: {
      type: Number,
      default: 10
    },
    sliderMd: {
      type: Number,
      default: 9
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: null
    },
    suffix: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      val: '0',
      sliderVal: 0,
      isFocused: false
    }
  },
  computed: {
    calculatedInputClass () {
      const oResultCssClasses = {}
      if (typeof this.inputClass === 'string') {
        const splittedClasses = this.inputClass.split(' ').filter(i => i.length)
        splittedClasses.map(cssClass => {
          oResultCssClasses[cssClass] = true
        })
      } else if (typeof this.inputClass === 'object') {
        Object.keys(this.inputClass).map(cssClass => {
          oResultCssClasses[cssClass] = this.inputClass[cssClass]
        })
      }

      if (this.state !== null) {
        oResultCssClasses['is-valid'] = this.state
        oResultCssClasses['is-invalid'] = !this.state
      }

      return oResultCssClasses
    }
  },
  beforeMount () {
    this.sliderVal = this.numberifyValue(this.value)
    this.val = this.isFocused ? this.value : this.stringifyValue(this.value)
  },
  methods: {
    stringifyValue (val) {
      return this.numberifyValue(val).toFixed(this.precision) + this.suffix
    },
    numberifyValue (val) {
      let floatVal = parseFloat(val.toString().replace(this.suffix, '').replace(',', '.'))
      if (isNaN(floatVal)) {
        floatVal = 0.0
      }

      if (floatVal > this.max) {
        floatVal = this.max
      } else if (floatVal < this.min) {
        floatVal = this.min
      }

      return parseFloat(floatVal.toFixed(this.precision))
    },
    onInput (newValue) {
      newValue = this.numberifyValue(newValue)
      this.sliderVal = newValue
      this.val = this.isFocused ? newValue : this.stringifyValue(newValue)

      this.$emit('input', newValue)
    },
    onInputSlider (newValue) {
      newValue = this.numberifyValue(newValue)
      this.val = this.stringifyValue(newValue)

      this.$emit('input', newValue)
    },
    onFocus (event) {
      this.isFocused = true
      this.val = this.numberifyValue(this.val)
      this.$emit('focus', event)
    },
    onBlur (event) {
      this.isFocused = false
      this.val = this.stringifyValue(this.val)
      this.$emit('blur', event)
    }
  },
  watch: {
    value (newValue) {
      this.sliderVal = this.numberifyValue(newValue)
      this.val = this.isFocused ? newValue : this.stringifyValue(newValue)
    }
  }
}
</script>
