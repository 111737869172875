import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  data: function () {
    return {
      chartOptions: {
        defaultFontFamily: 'Nunito',
        title: { display: false, text: '', fontColor: '#231f20' },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: '#231f20'
          }
        },
        scales: {
          xAxes: [{
            gridLines: { display: true },
            ticks: {
              fontColor: '#231f20',
              beginAtZero: true,
              fontFamily: 'Nunito'
            }
          }],
          yAxes: [{
            display: true,
            gridLines: { display: true },
            ticks: {
              fontColor: '#231f20',
              beginAtZero: true,
              fontFamily: 'Nunito',
              callback: function (value, index, values) {
                return formatNumberToFull(value) + ' kr'
              }
            }
          }]
        },
        tooltips: {
          titleFontFamily: "'Nunito'",
          bodyFontFamily: "'Nunito'",
          backgroundColor: '#FFF',
          titleFontSize: 16,
          titleFontColor: '#e1abaa',
          bodyFontColor: '#000',
          bodyFontSize: 14,
          displayColors: false,
          callbacks: {
            label: function (tooltipItem) {
              tooltipItem.yLabel = tooltipItem.yLabel.toFixed(2)
              return formatNumberToFull(tooltipItem.yLabel) + ' kr'
            }
          }
        },
        pointDot: true,
        pointDotRadius: 5,
        pointDotStrokeWidth: 1,

        onAnimationComplete: null,
        scaleLineColor: 'rgba(0,0,0,.25)',

        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
